import {NgModule} from '@angular/core';
import {detailDialog} from './detailDialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatCardModule } from "@angular/material";
@NgModule({
    imports: [
        MatDialogModule,
        MatCardModule
      ],
declarations:[detailDialog],
exports:[detailDialog]
})
export class detailDialogModule
{
  
   
}