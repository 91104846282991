import {Identity} from './identity';

export class Timestamp extends Identity {
  createdAt: string;
  updatedAt: string;

  constructor() {
    super();
  }
}

