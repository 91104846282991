import {Timestamp} from './timestamp';
import {City} from './city';
import {User} from './user';

export class FlightRoute extends Timestamp {
  arrivalId: string;
  arrival: City = new City();
  departureId: string;
  departure: City = new City();
  tokenPerSeat: number;
  userId: string;
  user: User;
}

