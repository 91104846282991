export enum UserRoleEnum {
  ADMIN = 1,
  SUPPORT = 2,
  CONSUMER = 3,
  CLEARANCE_WORKER = 4,
  TRAVEL_AGENT = 5,
}

export namespace UserRoleEnum {
  export function label(userRoleEnum: UserRoleEnum): string {
    switch (userRoleEnum) {
      case this.ADMIN:
        return 'Admin';
      case this.SUPPORT:
        return 'Support';
      case this.CONSUMER:
        return 'consumer';
      case this.CLEARANCE_WORKER:
        return 'Clearance Worker';
      case this.TRAVEL_AGENT:
        return 'Travel Agent';
      default:
        return '';
    }
  }
}
