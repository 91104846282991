import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../../models/api.response';
import {AppConfig} from '../../../config/app.config';
import {Flight} from '../../models/flight';
import {Pagination} from '../../models/pagination';
import {Filter} from '../../models/filter';

@Injectable({
  providedIn: 'root'
})
export class FlightService {

  constructor(public http: HttpClient) {
  }

  getFlight(id): Observable<ApiResponse<Flight>> {
    const url = `${AppConfig.URL}/api/flights/${id}`;
    return this.http.get<ApiResponse<Flight>>(url);
  }

  getFlights(filter: Filter = {}, sort: string = '', pageNumber: number = 0, limit: number = 0)
    : Observable<ApiResponse<Pagination<Flight>>> {
    const url = `${AppConfig.URL}/api/flights?filter=${JSON.stringify(filter)}&page=${pageNumber}&limit=${limit}&sort=${sort}`;
    return this.http.get<ApiResponse<Pagination<Flight>>>(url);
  }

  createFlight(flight: FormData): Observable<ApiResponse<Flight>> {
    const url = `${AppConfig.URL}/api/flights`;
    return this.http.post<ApiResponse<Flight>>(url, flight);
  }

  updateFlight(id, flight: any): Observable<ApiResponse<Flight>> {
    const url = `${AppConfig.URL}/api/flights/${id}`;
    return this.http.patch<ApiResponse<Flight>>(url, flight);
  }

  deleteImage(id, imageId): Observable<ApiResponse<Flight>> {
    const url = `${AppConfig.URL}/api/flights/${id}/images/${imageId}`;
    return this.http.delete<ApiResponse<Flight>>(url);
  }
}
