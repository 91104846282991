import { Component,Inject ,OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface dialogData {
  departure: 'islamabad';
  arrival: string;
  tokenPerSeat: number;
  createdAt: string;
  updatedAt: string;
}


@Component({
    selector: 'detailDialog',
    templateUrl: 'detailDialog.html',
    styleUrls: ['dialog.css'],
  })
  
  export class detailDialog  {  
    //routeData: PeriodicElement[] = [];
    constructor(
      public dialogRef: MatDialogRef<detailDialog>,
      @Inject(MAT_DIALOG_DATA) public data :dialogData ) {  //@Inject(MAT_DIALOG_DATA) public data
               console.log("DAta",)
               
      }
    onNoClick(): void {
      this.dialogRef.close();
    }
    
}