export class Item {
  key: any;
  value: any;


  constructor(key: any, value: any) {
    this.key = key;
    this.value = value;
  }
}

