import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../../models/api.response';
import {AppConfig} from '../../../config/app.config';
import {Schedule} from '../../models/schedule';
import {Pagination} from '../../models/pagination';
import {Filter} from '../../models/filter';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(public http: HttpClient) {
  }

  getSchedule(id): Observable<ApiResponse<Schedule>> {
    const url = `${AppConfig.URL}/api/schedules/${id}`;
    return this.http.get<ApiResponse<Schedule>>(url);
  }

  getSchedules(filter: Filter = {}, sort: string = '', pageNumber: number = 0, limit: number = 0): Observable<ApiResponse<Pagination<Schedule>>> {
    const url = `${AppConfig.URL}/api/schedules?filter=${JSON.stringify(filter)}&page=${pageNumber}&limit=${limit}&sort=${sort}`;
    return this.http.get<ApiResponse<Pagination<Schedule>>>(url);
  }

  createSchedule(schedule: Schedule): Observable<ApiResponse<Schedule>> {
    const url = `${AppConfig.URL}/api/schedules`;
    return this.http.post<ApiResponse<Schedule>>(url, schedule);
  }

  updateSchedule(id, schedule: Schedule): Observable<ApiResponse<Schedule>> {

    const url = `${AppConfig.URL}/api/schedules/${id}`;
    return this.http.patch<ApiResponse<Schedule>>(url, schedule);
  }
}
