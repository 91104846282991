import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {ApiResponse} from '../../models/api.response';
import {AppConfig} from '../../../config/app.config';
import {Pagination} from '../../models/pagination';
import {Filter} from '../../models/filter';
import {UserRoleEnum} from '../../enum/user-role.enum';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient) {
  }

  login(user: User): Observable<ApiResponse<User>> {
    const url = `${AppConfig.URL}/api/auth`;
    return this.http.post<ApiResponse<User>>(url, user);
  }

  signUp(user: User): Observable<ApiResponse<User>> {
    delete user.confirmPassword;
    const url = `${AppConfig.URL}/api/users`;
    return this.http.post<ApiResponse<User>>(url, user);
  }

  forgot(user: User): Observable<ApiResponse<Object>> {
    const url = `${AppConfig.URL}/api/auth/forgot`;
    return this.http.post<ApiResponse<Object>>(url, user);
  }

  reset(user: User): Observable<ApiResponse<Object>> {
    const url = `${AppConfig.URL}/api/auth/reset`;
    return this.http.post<ApiResponse<Object>>(url, user);
  }

  createUser(user: any): Observable<ApiResponse<User>> {
    const url = `${AppConfig.URL}/api/users`;
    return this.http.post<ApiResponse<User>>(url, user);
  }

  updateUser(id, user: any): Observable<ApiResponse<User>> {

    const url = `${AppConfig.URL}/api/users/${id}`;
    return this.http.patch<ApiResponse<User>>(url, user);
  }

  getUsers(filter: Filter = {}, sort: string = '', pageNumber: number = 0, limit: number = 0): Observable<ApiResponse<Pagination<User>>> {
    filter['role'] = {
      match: UserRoleEnum.CONSUMER
    };
    const url = `${AppConfig.URL}/api/users?filter=${JSON.stringify(filter)}&page=${pageNumber}&limit=${limit}&sort=${sort}`;
    return this.http.get<ApiResponse<Pagination<User>>>(url);
  }

  getUser(id): Observable<ApiResponse<User>> {
    const url = `${AppConfig.URL}/api/users/${id}`;
    return this.http.get<ApiResponse<User>>(url);
  }

}
